<template>
    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
        <ol class="carousel-indicators">
            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"></li>
            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"></li>
            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"></li>
            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <video class="w-100" loop autoplay muted=true>
                    <source src="https://i.gyazo.com/3706b6d909091d84be6bf28738b9ce1f.mp4" type="video/mp4">
                </video>
                <div class="carousel-caption w-75 mx-auto">
                    <div class="d-none d-md-block carousel-caption-bg">
                        <p>1. Pick a deck</p>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <video class="w-100" loop autoplay muted=true>
                    <source src="https://i.gyazo.com/d3832138268e0b02318d9a4592f76319.mp4" type="video/mp4">
                </video>
                <div class="carousel-caption w-75 mx-auto">
                    <div class="d-none d-md-block carousel-caption-bg">
                        <p>2. Add words to learn</p>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <video class="w-100" loop autoplay muted=true>
                    <source src="https://i.gyazo.com/95e1e08fba01fa424c161c76d54e484c.mp4" type="video/mp4">
                </video>
                <div class="d-none d-md-block carousel-caption w-50 mx-auto">
                    <div class="d-none d-md-block carousel-caption-bg">
                        <p>3. Learn Items</p>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <video class="w-100" loop autoplay muted=true>
                    <source src="https://i.gyazo.com/9dcd65578e4fafada2d1945f328abff9.mp4" type="video/mp4">
                </video>
                <div class="d-none d-md-block carousel-caption w-50 mx-auto">
                    <div class="d-none d-md-block carousel-caption-bg">
                        <p>4. Review periodically</p>
                    </div>
                </div>
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </a>
    </div>
</template>

<script>
import { Carousel } from 'bootstrap'

export default {
    name: 'Carousel',
}
</script>

<style lang="sass" scoped>
.carousel-inner
    padding: 10%
.carousel-control-next, .carousel-control-prev, .carousel-indicators
    filter: invert(1)
.carousel-inner
    .carousel-caption
        .carousel-caption-bg
            background-color: #232323
            border-radius: 2px
    video
        border: 1px solid black
        border-radius: 3px
</style>
