<template>
<div id="welcome" class="marg-big mx-auto">
    <!--breadcrumb-->
    <nav class="navbar navbar-light bg-light rounded" aria-label="breadcrumb">
        <div class="container-fluid">
            <ol class="breadcrumb">
                <li class="breadcrumb-item nav-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active nav-item"><a href="#">Features</a></li>
            </ol>
        </div>
    </nav>
    <!--headline-->
    <section role="tagline">
        <div class="row call-to-action">
            <div class="col-12 marg-big tag-line" >
                <h2 class="non-emphasis">
                    <span>Japanese is hell.</span>
                </h2>
                    <br><br>
                <h2 class="emphasis">
                    <span class="anim-fade-in"><span class="accent">Koohī</span> makes it bearable.</span>
                </h2>
            </div>
            <div class="col-12 marg-big">
                <button class="col-8 col-md-2 mx-auto btn btn-contextual" data-bs-toggle="modal" data-bs-target="#loginModal" @click="register()">Create a Free Account</button>
            </div>
        </div>
    </section>
    <section class="col-11" role="cell-1">
        <div class="row marg-big">
            <div class="col-12">
                <h2>Reading is rough.</h2>
            </div>
            <div class="col-12 col-md-6">With a lack of shared vocabulary, etymology, and grammar patterns, reading the books you want to read in Japanese can be a difficult task.</div>
            <div class="col-12 col-md-6">
                <div class="row">
                    <div class="align-center col-6 col-md-4 mx-auto">
                        <h2 class="accent">869</h2>
                        <div>Avg unique kanji in a children's novel</div>
                    </div>
                    <div class="align-center col-6 col-md-4 mx-auto">
                        <h2 class="accent">3,068</h2>
                        <div>Avg unique words in a children's novel</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="col-11" role="cell-2">
        <div class="row marg-big">
            <div class="col-12">
                <h2><span class="accent">Koohī</span> fixes that.</h2>
            </div>
            <div class="col-12 col-md-6">Koohī is an SRS (spaced repetition system) learning resource designed around the idea of pre-learning content.<br /><br />Learning vocabulary before it appears in a book eliminates the pressure to constantly have a dictionary open, leaving readers with a smooth reading experience.</div>
            <div class="col-12 col-md-6">
                <Carousel />
            </div>
        </div>
    </section>
    <section role="feature-group-2">
        <div class="row marg-big">
            <div class="col-12 marg-big">
                <h2>A full-package, web solution</h2>
            </div>
            <div class="col-8 col-sm-4 collapse-pad mx-auto">
                <div class="card w-100 mx-auto" style="width: 18rem;">
                    <div class="w-100 card-fa align-center">
                        <i class="fas fa-cog"></i>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">Machine-Assisted Learning</h5>
                        <p class="card-text">Koohī's Spaced Repetition System (SRS) ensures that you're studying the flash cards you need by adjusting review timers based on accuracy.</p>
                    </div>
                </div>
            </div>
            <div class="col-8 col-sm-4 collapse-pad mx-auto">
                <div class="card w-100 mx-auto" style="width: 18rem;">
                    <div class="w-100 card-fa align-center">
                        <i class="fas fa-book"></i>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">Prebuilt Decks</h5>
                        <p class="card-text">200+ prebuild decks, covering 30,0000+ unique words. All ready with the click of a button.</p>
                    </div>
                </div>
            </div>
            <div class="col-8 col-sm-4 collapse-pad mx-auto">
                <div class="card w-100 mx-auto" style="width: 18rem;">
                    <div class="w-100 card-fa align-center">
                        <i class="fas fa-search"></i>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">Dictionary</h5>
                        <p class="card-text">Add words directly from a dictionary, or even create your own custom cards.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section role="feature-group-2">
        <div class="row marg-big">
            <div class="col-12 marg-big">
                <h2>Flexible Decks</h2>
            </div>
            <div class="col-6 col-md-3 collapse-pad">
                <div class="card w-100 mx-auto" style="width: 18rem;">
                    <div class="w-100 card-fa align-center">
                        <i class="fas fa-blender"></i>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">Mix n' Match</h5>
                        <p class="card-text">All decks can be combined with others to create lists more conducive to the media you consume.</p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 collapse-pad">
                <div class="card w-100 mx-auto" style="width: 18rem;">
                    <div class="w-100 card-fa align-center">
                        <i class="fas fa-flag"></i>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">All Native</h5>
                        <p class="card-text">All decks built directly from native, Japanese media with words learnable in the order they appear in the source.</p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 collapse-pad">
                <div class="card w-100 mx-auto" style="width: 18rem;">
                    <div class="w-100 card-fa align-center">
                        <i class="fas fa-flask"></i>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">Flexible Filtering</h5>
                        <p class="card-text">Control the size of decks by filtering out according to factors such as word frequency.</p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 collapse-pad">
                <div class="card w-100 mx-auto" style="width: 18rem;">
                    <div class="w-100 card-fa align-center">
                        <i class="fas fa-brain"></i>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">Decks That Learn</h5>
                        <p class="card-text">All decks are dynamically synced to your account - learning words on one deck affects the rest, meaning everything is personalized to your needs.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section role="feature-group-3">
        <div class="row marg-big">
            <div class="col-12 marg-big">
                <h2>A Modern SRS</h2>
            </div>
            <div class="col-6 col-md-3 collapse-pad">
                <div class="card w-100 mx-auto" style="width: 18rem;">
                    <div class="w-100 card-fa align-center">
                        <i class="fas fa-ban"></i>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">No Multiple Choice</h5>
                        <p class="card-text">Koohī checks that you know your words by letting you type your answer, instead of by guessing easy multiple choice questions.</p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 collapse-pad">
                <div class="card w-100 mx-auto" style="width: 18rem;">
                    <div class="w-100 card-fa align-center">
                        <i class="fas fa-music"></i>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">Pitch Accents</h5>
                        <p class="card-text">Assists in mastering pronunciation through the integration of pitch acccents, a linguistic feature normally reserved for specialized dictionaries.</p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 collapse-pad">
                <div class="card w-100 mx-auto" style="width: 18rem;">
                    <div class="w-100 card-fa align-center">
                        <i class="fas fa-quote-right"></i>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">Example Sentences</h5>
                        <p class="card-text">Example sentences gathered from novels appear during review sessions to help you nail down what definitions you can expect to see.</p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 collapse-pad">
                <div class="card w-100 mx-auto" style="width: 18rem;">
                    <div class="w-100 card-fa align-center">
                        <i class="fas fa-gamepad"></i>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">Gamification</h5>
                        <p class="card-text">Have fun while learning through the introduction of semi-competitive aspects such as xp.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section role="space" style="height:15vh"></section>
    <section role="tagline">
        <div class="row call-to-action">
            <div class="col-12 marg-big tag-line align-center" >
                <h2 class="emphasis">
                    <span class="anim-fade-in">Graduate from textbooks with <span class="accent">Koohī,</span> <span style="display: inline-block;transform: skewX(-12deg)">free forever.</span></span>
                </h2>
                <div class="col-12 marg-big align-center">
                    <button
                        class="btn btn-contextual"
                        @click="register()"
                        data-bs-toggle="modal"
                        data-bs-target="#loginModal"
                        style="min-height:3em"
                    >Join Koohī Today</button>
                </div>
            </div>
        </div>
    </section>
    <section role="space" style="height:15vh"></section>
</div>
</template>

<script>
import Carousel from './Carousel.vue'

export default {
    name: 'Welcome',
    data() {
        return {}
    },
    components: {
        Carousel
    },
    methods: {
        register() {
            this.$emit('setup-register')
        }
    }
}
</script>

<style lang="sass" scoped>
body
    #welcome
        .btn-contextual
            border-color: #28a745
            background-color: #28a745
            color: white!important
    &.dark
        #welcome
            div
                color: rgba(255,255,255, .87)
            .btn-contextual
                border-color: #dc3545
                background-color: #dc3545
#welcome
    font-size: 1.2rem
    line-height: 2
    /* bootstrap default, overrides roboto */
    div
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
    .w-90
        width: 90%!important
    h2
        font-size: 1.75rem
    section
        h2:first-of-type
            margin-bottom: 1rem
        margin-bottom: 6rem
        &[role="tagline"]
            margin-bottom: 9rem
    .collapse-pad
        @media (max-width: 576px)
            padding: 0!important
    .card
        padding-top: 2rem
        padding-bottom: 2rem
        height: 100%
        .card-text
            font-size: 1.1rem
        .card-title
            text-align: center
            color: orange
        .card-fa
            i
                font-size: 5em!important
                margin: 1rem 0
                color: rgba(0, 167, 249, .77)
    .accent
        color: orange
    .tag-line
        h2.non-emphasis
            font-size: 2.25rem
            opacity: .9
        h2.emphasis
            font-size: 2.5rem
    hr
        border: 0
        height: 0
        border-top: 1px solid rgba(0, 0, 0, 0.1)
        border-bottom: 1px solid rgba(255, 255, 255, 0.3)

</style>
